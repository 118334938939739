@import "../../styles/variables.scss";

.content_flex {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // padding: 4rem 0;
  padding-bottom: 4rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 0;
  }
}

.right {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;

  & > div {
    flex: 1 1 auto;
  }

  @media screen and (min-width: 768px) {
    max-width: 50%;
    padding-left: 0.5rem;
  }
}

.left {
  flex: 1 1 auto;
  height: 300px;

  @media screen and (min-width: 768px) {
    max-width: 50%;
    padding-right: 0.5rem;
    height: 631px;
  }
}

.card {
  height: 100%;
  width: 100%;
  background-color: $secondary-dark;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 2rem;
  text-align: center;
  justify-content: center;
  box-shadow: $box-shadow;
}
