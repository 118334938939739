.small_hero {
  height: 12rem;
  background-position: center;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;

  & h1 {
    font-size: 2.5rem;
    font-weight: 700;

    @media screen and (min-width: 768px) {
      font-size: 4rem;
    }
  }
}

.small_hero_content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 100;
}
