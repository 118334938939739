@import "../../styles/variables.scss";

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: $font-family-copy;
  font-weight: 700;

  & p {
    font-size: 0.9rem;
    color: $brown;
  }

  & label {
    display: flex;
    flex-direction: column;

    input:focus,
    textarea:focus {
      outline: none;
    }

    & input,
    textarea {
      min-height: 60px;
      padding: 1rem;
      border: 1px solid $main-dark;
      font-family: $font-family-copy;
      font-size: 1rem;
    }

    & textarea {
      height: 200px;
      resize: none;
    }
  }

  & button {
    color: $main-light;
    height: 60px;

    &:hover {
      color: $main-light;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}
