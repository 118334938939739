@import "../../styles/variables.scss";

.book_section {
  // background-color: $main-dark;
  min-height: 80vh;
  height: fit-content;
  padding: 2rem 0;
}

.platform {
  padding: 2rem;
  background-color: white;
  box-shadow: $box-shadow;
}

.content_flex {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  & * {
    color: $main-dark;
  }
}

.left {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: 768px) {
    max-width: 50%;
  }
}

.right {
  @extend .left;
}

.opening_hours {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
}
