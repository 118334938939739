@import "../../styles/variables.scss";

section {
  padding-bottom: 0;
}

.reviews_parent {
  display: flex;
  gap: 1.5rem;
  padding-top: 2rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-top: 1rem;
  }

  & * {
    color: $main-dark;
    text-align: center;
    font-family: $font-family-copy;
  }
}

.reviews_child {
  flex: 1 1 33%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
