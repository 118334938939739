@import "../../styles/variables.scss";

.slider {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: $box-shadow;
  height: 300px;

  @media screen and (min-width: 768px) {
    height: 631px;
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 1s;
  opacity: 0;
}

.show {
  opacity: 1;
}
