@import "../../styles/variables.scss";

.content_flex {
  display: flex;
  min-height: 160px;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    text-align: left;
  }
}

.footer_card {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  & * {
    color: $main-dark;
  }
}

.logo {
  & a {
    & img {
      height: 60px;
    }
  }
}

.icon_wrap {
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;

  & .icon {
    height: 60px;
    width: 60px;
  }
}
