@import "../../styles/variables.scss";

.hero {
  min-height: calc(100vh - 92px);
  background-color: black;
  background-position: center -6rem;
  background-repeat: no-repeat;
  background-size: 1400px;
  position: relative;
  display: flex;
  align-items: center;
}

.hero_content {
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  & h1 {
    font-family: $font-family-heading;
    font-weight: 800;
    font-size: calc(2rem + 4vw);
    color: $main-light;

    @media (min-width: 768px) {
      font-size: calc(4rem + 2vw);
    }

    @media (min-width: 1024px) {
      font-size: 6rem;
    }
  }

  & h3 {
    font-family: $font-family-dancing;
    font-size: 2rem;
    font-weight: 200;

    @media (min-width: 768px) {
      font-size: 3rem;
    }
  }

  & p {
    font-family: $font-family-copy;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 1rem;
  }

  & .bold {
    font-weight: 600;
  }

  & a {
    margin-top: 1rem;
  }
}

.arrowDiv {
  margin-top: 3rem;

  & img {
    height: 60px;
    width: 60px;
  }
}
