@import "../../styles/variables.scss";

.value {
  font-weight: 200;
  @media screen and (min-width: 768px) {
    width: 12rem;
  }
}

.price {
  @extend .value;
}

.shape_wrap {
  padding-bottom: 2rem;
  border-bottom: 1px solid white;
}

.accordion {
  display: block;
  background-color: $main-dark;
  margin-top: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & p,
  h4 {
    color: $main-light;
    font-family: $font-family-heading;
  }
  & h4 {
    font-weight: 200;
  }
}
