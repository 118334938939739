@import "../../styles/variables.scss";

.concept_section {
  padding: 0rem;

  @media screen and (min-width: 768px) {
    padding: 4rem 0 4rem;
  }
}

.content_flex {
  display: flex;
  gap: 1rem;
  flex-direction: column;

  & > .fade {
    width: 100%;
    display: flex;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.card {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: $main-light;

  & * {
    color: $main-dark;
  }
}

.left {
  flex: 1 1 auto;
}

.right {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & h4 {
    color: $main-dark;
    text-align: center;
    padding-bottom: 1rem;
  }

  & .card {
    background-color: $secondary-dark;
    height: 100%;
    justify-content: center;

    & * {
      color: $main-light;
    }
  }

  @media screen and (min-width: 768px) {
    max-width: 50%;
  }
}

.card_img_div {
  max-width: 60px;
  max-height: 60px;
  margin-bottom: 1rem;

  & img {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    object-fit: cover;
  }
}

.main {
  overflow: hidden;
}

.heading_div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;

  @media screen and (min-width: 768px) {
    padding: 2rem 0 7rem;
  }

  & h1 {
    font-size: 2rem;
    font-weight: 800;

    @media screen and (min-width: 768px) {
      font-size: 2.5rem;
    }
  }
}
