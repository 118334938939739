$main-dark: #000000;
$secondary-dark: #121212;
$main-light: #ededed;
$grey: #bfbfbf;
$blue: #73b4b7;
$brown: #b46800;
$warning: #ac0000;

$font-family-dancing: "Dancing Script", cursive;
$font-family-heading: "Montserrat", sans-serif;
$font-family-copy: "Quattrocento", serif;

$box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
