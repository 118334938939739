@import "../../styles/variables.scss";

.FAQ_page_section {
  padding: 1rem 0;

  @media screen and (min-width: 768px) {
    padding: 3rem 0;
  }

  & * {
    color: $main-dark;
  }
}

.content_flex {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 4rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 0;
  }
}

.left {
  flex: 1 1 auto;
  padding-top: 0rem;

  @media screen and (min-width: 768px) {
    max-width: 40%;
    padding-right: 0.5rem;
    height: 631px;
    padding-top: 4rem;
  }
}

.select_wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  & button {
    font-size: 1rem;
    color: $main-light;
    padding: 1rem 2rem;
    width: 100%;
  }
}

.right {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    max-width: 60%;
    padding-left: 0.5rem;
  }

  & h2 {
    padding: 0rem 1rem 1rem;
  }

  & div:nth-child(2) > h2,
  & div:nth-child(3) > h2 {
    padding-top: 4rem;
  }
}
