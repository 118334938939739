@import "../../styles/variables.scss";

main > section {
  padding: 1rem 0;
}

.flex_parent {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.flex_parent_2 {
  @extend .flex_parent;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
}

.flex_child {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .header_div {
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & h2 {
      color: $main-dark;
      text-align: center;
      font-family: $font-family-heading;
    }
  }

  .background_div {
    flex: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 22rem;
  }
}
