@import "../../styles/variables.scss";

.accordion_wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  & * {
    transition: all 0.3s ease-in-out;
  }
}

.accordion {
  border: 1px solid $main-dark;
  padding: 1rem 2rem 0rem;

  &:hover {
    cursor: pointer;
  }
}

.q_wrap {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding-bottom: 1rem;

  & * {
    color: $main-dark;
    font-size: 1.3rem;
    font-family: $font-family-copy;

    @media screen and (min-width: 768px) {
      font-size: 1.5rem;
    }
  }
}

.answer_div {
  border-top: 1px solid $main-dark;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease;

  & p {
    color: $main-dark;
  }
}

.answer_div.show {
  opacity: 1;
  max-height: 10rem;
  padding: 1rem 0;
}
