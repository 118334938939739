@import "../../styles/variables.scss";

.findUsSection {
  background-color: $main-dark;
  min-height: 100vh;
  height: fit-content;
  display: flex;
  align-items: center;
}

.heading {
  margin: auto;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 2.5rem;
  margin: 4rem auto;

  & h1 {
    color: $main-light;
    font-size: 2rem;
    font-weight: 800;

    @media screen and (min-width: 768px) {
      font-size: 2.5rem;
    }
  }
}

.content_flex {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center; // space-between when there are more cards
  padding-bottom: 4rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 1rem;

    & > div {
      flex: 1 1 50%;
      display: flex;
      max-width: 600px;
    }
  }
}

.card {
  padding: 2rem;
  background-color: $main-light;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
  gap: 0.5rem;

  @media screen and (min-width: 768px) {
    max-width: 600px; // Remove when there are more cards
  }

  & h4 {
    color: $main-dark;
  }

  & p {
    color: $main-dark;
  }

  & > button {
    margin-top: 2rem;
  }
}

.card_two {
  @extend .card;
  justify-content: center;
}

.button_flex {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}
