.banner {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 2rem;
  text-align: center;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 90%;
  max-width: 600px;

  & * {
    font-family: "Quattrocento", serif;
    font-size: 1rem;
  }
}

.acceptButton {
  background-color: #fff;
  color: #000;
  padding: 1rem 2rem;
  width: 100%;
  cursor: pointer;
}

.declineButton {
  background-color: transparent;
  color: #fff;
  padding: 1rem 2rem;
  margin: 10px;
  font-size: 0.8rem;
  cursor: pointer;
}
