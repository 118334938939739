@import "../../styles/variables.scss";

.logo {
  height: 60px;
  & a {
    & img {
      height: 60px;
      // width: 114px;
    }
  }
}

.header_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.languageButton {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  border: 1px solid #ededed;
  display: flex;
  justify-content: center;
}

.hamburgerDiv {
  z-index: 1000;
  width: 60px;
  height: 60px;
  border: 1px solid $main-light;
  border-radius: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    display: none;
  }
}

.nav_ul {
  transition: all 300ms ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  transform: translate(100%);
  gap: 3rem;
  height: calc(100vh);
  width: 100%;
  background-color: $main-dark;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;
  z-index: 999;

  &.active {
    transform: translate(0);
  }

  @media (min-width: 768px) {
    position: relative;
    transform: translate(0);
    height: fit-content;
    width: fit-content;
    flex-direction: row;
    padding: 0;
    background-color: transparent;
    gap: 1rem;
  }
}

.active-link {
  color: white;
}
