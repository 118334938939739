@import "../../styles/variables.scss";

.whatWeDoSection {
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.heading {
  margin: auto;
  display: flex;
  margin-top: 2rem;
  width: fit-content;
  align-items: center;
  gap: 1rem;

  @media screen and (min-width: 768px) {
    margin-top: 3rem;
  }

  & h1 {
    color: $main-dark;
    font-size: 2rem;
    font-weight: 800;

    @media screen and (min-width: 768px) {
      font-size: 2.5rem;
    }
  }
}

.button_div {
  width: 100%;
  text-align: center;
  padding-bottom: 4rem;
  height: fit-content;
  display: flex;
  justify-content: center;
}
