@import "../../styles/variables.scss";

.FAQ_section {
  min-height: 100vh;
  height: fit-content;
  display: flex;
  align-items: center;
}

.heading {
  margin: auto;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 2.5rem;
  margin: 4rem auto;

  & h1 {
    color: $main-dark;
    font-size: 2rem;
    font-weight: 800;

    @media screen and (min-width: 768px) {
      font-size: 2.5rem;
    }
  }
}

.content_flex {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: space-between;
  padding-bottom: 4rem;
  overflow: hidden;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 1rem;

    & > div {
      flex: 1 1 50%;
      display: flex;
    }
  }
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  & a {
    width: fit-content;
  }

  & h2 {
    text-align: center;
    color: $main-dark;
  }
}
