@import "../../styles/variables.scss";

.hamburger {
  width: 30px;
  height: 22px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    width: 100%;
    height: 2px;
    background-color: $main-light;
    transition: all 0.3s ease-in-out;
  }

  &.active {
    span:nth-child(1) {
      transform: rotate(-45deg) translate(-7px, 7px);
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(3) {
      transform: rotate(45deg) translate(-7px, -7px);
    }
  }
}
