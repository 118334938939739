@import "./variables.scss";
@import "./buttons.scss";

html,
body,
#root,
ul,
li,
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
  color: $main-light;
}

a {
  color: $grey;
  text-decoration: none;
  font-size: 1.2rem;
}

html,
body,
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $main-light;
}

header {
  background-color: $main-dark;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: $font-family-copy;
}

h1 {
  font-family: $font-family-heading;
}

h2 {
  font-size: 2rem;
  font-weight: 900;
  font-family: $font-family-copy;

  @media screen and (min-width: 768px) {
    font-size: 2.5rem;
  }
}

h4 {
  font-family: $font-family-copy;
  font-size: 1.5rem;
  color: $main-light;
}

h5 {
  @extend h4;
  font-size: 1.2rem;
}

p {
  font-family: $font-family-copy;

  @media screen and (min-width: 768px) {
    font-size: 1.1rem;
  }
}

.main-dark {
  color: $main-dark;
}

.Routes {
  flex: 1;
}

footer {
  background-color: $main-light;
  border-top: 1px solid rgb(102, 102, 102);
  min-height: 200px;
  width: 100%;
}

.container {
  margin: 0 auto;
  max-width: 1400px;
  padding: 1rem 0rem;
  width: 100%;
}

.wrap {
  padding: 0rem 1rem;
}

section {
  height: fit-content;
}

.active-link {
  color: white;
}

.underline {
  height: 4px;
  width: 60%;
  max-width: 460px;
}

.gradient {
  background: linear-gradient(to right, $blue, $brown);
}

.vertical-line {
  width: 6px;
  height: 2.5rem;
  border-radius: 4rem;
  margin-right: 1rem;
}

.gradient-vertical {
  background: linear-gradient(to bottom, $blue, $brown);
}

.overlay {
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.65;
  z-index: 1;
  position: absolute;
}

// UTIL

.mb-1 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 0.2rem;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: 1rem;
}

.font-heading {
  font-family: $font-family-heading;
}

.font-copy {
  font-family: $font-family-copy;
}

.font-bold {
  font-weight: 800;
}

.font-light {
  font-weight: 200;
}

.sm-justify-between {
  @media screen and (max-width: 768px) {
    justify-content: space-between;
  }
}

.w-100 {
  width: 100%;
}

.warning {
  color: $warning;
}
