button {
  cursor: pointer;
  border: none;
  background: none;
  color: $main-light;
}

.cta {
  @extend button;
  padding: 1rem 3rem;
  border: 1px solid $main-light;
  font-size: 1.2rem;
  font-family: $font-family-copy;
  font-weight: 300;
  transition: all 300ms ease-in-out;
  display: block;

  &:hover {
    background-color: $main-light;
    color: $main-dark;
  }
}

.cta-dark {
  @extend button;
  @extend .cta;
  background-color: $secondary-dark;
  box-shadow: $box-shadow;

  &:hover {
    border: 1px solid $main-dark;
  }
}
