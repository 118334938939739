@import "../../styles/variables.scss";

.productCard {
  width: 100%;
  padding: 1rem;
  display: flex;
  margin-bottom: 2rem;
  flex-direction: column;
  background-color: $main-light;
  box-shadow: $box-shadow;

  & span {
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & p,
    h4,
    button,
    span {
      color: $main-dark;
    }

    // media queries
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    .left {
      flex: 2;

      & img {
        height: 100%;
        width: 100%;
        max-height: 300px;
        object-fit: cover;
        display: flex;
        object-position: center;
      }
    }

    .right {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;
      & > div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }

  .accordion {
    display: none;
    width: 100%;
    // Your accordion styles here
  }
}
